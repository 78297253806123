/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface TwitterProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Twitter: React.FC<TwitterProps> = ({size, ...props}) => (
  <svg viewBox="0 0 18 14" fill="currentColor" width={ size || "18" } height={ size || "14" } {...props}>
    <path d="M50.0768084,18.6572894 C49.4120716,18.9383915 48.6969358,19.1284894 47.9467859,19.2134267 C48.7133818,18.7761005 49.3011937,18.0839623 49.5775926,17.2588567 C48.8603348,17.6638258 48.0677436,17.9575674 47.2215703,18.1158138 C46.5467537,17.4287314 45.5817448,17 44.5148767,17 C42.4676148,17 40.8070991,18.5824636 40.8070991,20.5340002 C40.8070991,20.8115633 40.8389301,21.0805316 40.902592,21.3388827 C37.8208235,21.1912535 35.0886652,19.7852371 33.2583844,17.6456249 C32.9390136,18.1694052 32.7565161,18.7761005 32.7565161,19.4242534 C32.7565161,20.6487667 33.411173,21.7312123 34.4064214,22.3657145 C33.7984499,22.3480192 33.2260229,22.187245 32.7257461,21.9243436 L32.7257461,21.9678235 C32.7257461,23.6807266 34.0037596,25.1094941 35.7014114,25.4330649 C35.3899984,25.5159799 35.0626699,25.5574374 34.7242006,25.5574374 C34.4849378,25.5574374 34.2520412,25.5362031 34.0265718,25.4947456 C34.4982007,26.8987397 35.867463,27.9210213 37.490312,27.9483226 C36.2213173,28.8967896 34.621811,29.4615218 32.884901,29.4615218 C32.5851593,29.4615218 32.2896618,29.4458488 32,29.4124806 C33.6414171,30.414539 35.5905335,31 37.6850114,31 C44.5074495,31 48.2364477,25.6155791 48.2364477,20.9440251 C48.2364477,20.7908346 48.2332646,20.637644 48.2268984,20.4869813 C48.9515835,19.98848 49.5813062,19.3656062 50.0768084,18.6572894"
      transform="translate(-32 -17)" fill="currentColor" fillRule="evenodd" />
  </svg>
);
Twitter.displayName = 'Twitter';
export default Twitter;
/* tslint:enable */
/* eslint-enable */
