import React from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useWidgetState, useWidgetActions} from '../../hooks/state-provider'
import {hasMoreEvents, isMoreEventsLoading} from '../../selectors/events'
import {Spinner} from '../spinner'
import {LoadMoreButtonProps} from './interfaces'
import s from './load-more-button.scss'

export const LoadMoreButton = ({t}: LoadMoreButtonProps) => {
  const hasMore = useWidgetState(hasMoreEvents)
  const moreLoading = useWidgetState(isMoreEventsLoading)
  const {loadEventsPage} = useWidgetActions()

  if (!hasMore && !moreLoading) {
    return null
  }

  if (moreLoading) {
    return (
      <div data-hook={DH.loadMoreButtonContainer} className={s.loader}>
        <Spinner />
      </div>
    )
  }

  return (
    <div data-hook={DH.loadMoreButtonContainer} className={s.container}>
      <button data-hook={DH.loadMoreButton} className={s.button} type="button" onClick={() => loadEventsPage()}>
        {t('loadMoreButton')}
      </button>
    </div>
  )
}
