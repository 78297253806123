/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface ExpandArrowProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const ExpandArrow: React.FC<ExpandArrowProps> = ({size, ...props}) => (
  <svg viewBox="0 0 18 10" fill="currentColor" width={ size || "18" } height={ size || "10" } {...props}>
    <path transform="rotate(-180 134.062 82)" d="M251 163L259.689655 155 268.123535 163" stroke="currentColor" fill="none" fillRule="evenodd" />
  </svg>
);
ExpandArrow.displayName = 'ExpandArrow';
export default ExpandArrow;
/* tslint:enable */
/* eslint-enable */
