import {WEEK_LENGTH} from '@wix/wix-events-commons-statics'
import {MonthlyCalendarCellSizes} from '../components/calendar/monthly/grid'
import {TriangleDirection} from '../components/calendar/monthly/grid/popup/triangle'

const POPUP_WIDTH_IN_CELLS = 2
const TRIANGLE_HEIGHT = 18

export const CALENDAR_WEEKS = 6

// TODO: write tests to verify if placement is calculated correctly
export const getPopupPlacement = (
  cellSizes: MonthlyCalendarCellSizes,
  borderWidth: number,
  popupContentHeight: number,
  week: number,
  weekDayIndex: number,
  rtl: boolean = false,
) => {
  weekDayIndex = rtl ? WEEK_LENGTH - 1 - weekDayIndex : weekDayIndex

  const leftCell = getPopupLeftCell(weekDayIndex)
  const left = getPopupLeft(cellSizes, borderWidth, leftCell, rtl)
  const width = getPopupWidth(cellSizes, borderWidth, leftCell)
  const verticalCells = getPopupVerticalCells(cellSizes, popupContentHeight)
  const topCell = getPopupTopCell(week, verticalCells)
  const top = getPopupTop(cellSizes, borderWidth, topCell)
  const height = getPopupHeight(cellSizes, borderWidth, topCell, verticalCells)
  const triangleDirection = getTriangleDirection(weekDayIndex)
  const triangleTop = getTriangleTop(cellSizes, borderWidth, topCell, week)

  return {styles: {left, top, width, height}, triangle: {direction: triangleDirection, top: triangleTop}}
}

const getPopupLeftCell = (weekDayIndex: number) => {
  const midDay = WEEK_LENGTH / 2 - 1

  if (weekDayIndex < midDay) {
    return weekDayIndex + 1
  } else {
    return weekDayIndex - 2
  }
}

const getPopupLeft = (cellSizes: MonthlyCalendarCellSizes, borderWidth: number, leftCell: number, rtl: boolean) => {
  const firstWeekDays = cellSizes[0]

  let left = 0

  for (let i = 0; i < leftCell; i++) {
    left += firstWeekDays[i].width + borderWidth
  }

  // Amazingly left seems to be calculated from border middle point
  left -= rtl ? Math.ceil(borderWidth / 2) : Math.floor(borderWidth / 2)

  return left
}

const getPopupWidth = (cellSizes: MonthlyCalendarCellSizes, borderWidth: number, leftCell: number) => {
  const firstWeekDays = cellSizes[0]

  let width = 0

  for (let i = leftCell; i < leftCell + POPUP_WIDTH_IN_CELLS; i++) {
    width += firstWeekDays[i].width
  }

  width += (POPUP_WIDTH_IN_CELLS + 1) * borderWidth

  return width
}

const getPopupVerticalCells = (cellSizes: MonthlyCalendarCellSizes, popupContentHeight: number) => {
  const firstWeekDays = cellSizes[0]
  const firstDay = firstWeekDays[0]

  return Math.min(Math.ceil(popupContentHeight / firstDay.height), CALENDAR_WEEKS)
}

const getPopupTopCell = (week: number, verticalCells: number) => {
  let topCell = week - Math.floor(verticalCells / 2)
  topCell = verticalCells % 2 ? topCell : topCell + 1

  if (topCell < 0) {
    topCell = 0
  } else if (topCell + verticalCells >= CALENDAR_WEEKS) {
    topCell = CALENDAR_WEEKS - verticalCells
  }

  return topCell
}

const getPopupTop = (cellSizes: MonthlyCalendarCellSizes, borderWidth: number, topCell: number) => {
  let top = 0

  for (let i = 0; i < topCell; i++) {
    top += cellSizes[i][0].height + borderWidth
  }

  // Amazingly top seems to be calculated from border middle point
  top -= Math.floor(borderWidth / 2)

  return top
}

const getPopupHeight = (
  cellSizes: MonthlyCalendarCellSizes,
  borderWidth: number,
  topCell: number,
  verticalCells: number,
) => {
  let height = 0

  for (let i = topCell; i < topCell + verticalCells; i++) {
    height += cellSizes[i][0].height
  }

  height += (verticalCells + 1) * borderWidth

  return height
}

const getTriangleDirection = (weekDayIndex: number) => {
  const midDay = WEEK_LENGTH / 2 - 1

  if (weekDayIndex < midDay) {
    return TriangleDirection.left
  } else {
    return TriangleDirection.right
  }
}

const getTriangleTop = (cellSizes: MonthlyCalendarCellSizes, borderWidth: number, topCell: number, week: number) => {
  let top = 0

  for (let i = topCell; i < week; i++) {
    top += cellSizes[i][0].height + borderWidth
  }

  top += Math.floor(cellSizes[week][0].height / 2 - TRIANGLE_HEIGHT / 2)

  return top
}
