import {createStylesParams, StyleParamType} from '@wix/tpa-settings'

export default createStylesParams<{
  cardBorderWidth: StyleParamType.Number
  listGeneralTitleFontSizeMobile: StyleParamType.Number
  listHeaderTitleFontSizeMobile: StyleParamType.Number
  borderWidth: StyleParamType.Number
  todayButtonBorderWidth: StyleParamType.Number
  todayButtonBorderRadius: StyleParamType.Number
  cardDescriptionFontSize: StyleParamType.Number
  listRoundedButtonBorderRadius: StyleParamType.Number
  listBorderWidth: StyleParamType.Number
  calendarCellBorderWidth: StyleParamType.Number
  calendarBorderWidth: StyleParamType.Number
  buttonsBorderWidth: StyleParamType.Number
  todayRoundedButtonBorderRadius: StyleParamType.Number
  listButtonBorderRadius: StyleParamType.Number
  listButtonBorderWidth: StyleParamType.Number
  cardLocationAndDateFontSize: StyleParamType.Number
  listButtonFontSizeMobile: StyleParamType.Number
  listContentTextFontSizeMobile: StyleParamType.Number
  calendarDetailsTitleTextSize: StyleParamType.Number
  calendarDetailsDateLocationTextSize: StyleParamType.Number
  calendarDetailsDescriptionTextSize: StyleParamType.Number
}>({
  cardBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 1
    },
  },
  listGeneralTitleFontSizeMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 28
    },
  },
  listHeaderTitleFontSizeMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 20
    },
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
  todayButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 1
    },
  },
  todayButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
  cardDescriptionFontSize: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 15
    },
  },
  listRoundedButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 100
    },
  },
  listBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
  calendarCellBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 1
    },
  },
  calendarBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
  buttonsBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 1
    },
  },
  todayRoundedButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 100
    },
  },
  listButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
  listButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 1
    },
  },
  cardLocationAndDateFontSize: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 16
    },
  },
  listButtonFontSizeMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 16
    },
  },
  listContentTextFontSizeMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 16
    },
  },
  calendarDetailsTitleTextSize: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 16
    },
  },
  calendarDetailsDateLocationTextSize: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 14
    },
  },
  calendarDetailsDescriptionTextSize: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 14
    },
  },
})
