import classNames from 'classnames'
import React from 'react'
import sc from '../../../classes.scss'
import sd from '../date/date.scss'
import s from './title-location.scss'

interface TitleProps {
  event: wix.events.Event
  showVenue: boolean
}

export const TitleLocation = ({event, showVenue}: TitleProps) => (
  <div className={classNames(s.container, {[s.noLocation]: !showVenue})}>
    <div
      className={classNames(s.title, sc.textLineHeight, sd.listHeaderTitle, sd.color)}
      data-hook="ev-list-item-title"
    >
      {event.title}
    </div>
    {showVenue && (
      <>
        <div className={classNames(sd.listHeaderTitle, sd.color)} aria-hidden="true">
          &nbsp;/&nbsp;
        </div>
        <div className={classNames(s.listHeaderLocation, s.location)} data-hook="ev-list-item-location">
          {event.location.name || event.location.address}
        </div>
      </>
    )}
  </div>
)
