import {withTranslation} from 'react-i18next'
import {isSingleEventWidget} from '../../../commons/selectors/settings'
import {isMembersEnabled} from '../../selectors/list-settings'
import {isMembersVisible} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {MembersOwnProps, MembersStateProps} from './interfaces'
import {Members as MembersPresentation} from './members'

const mapRuntime = ({actions, state}: AppProps, ownProps: MembersOwnProps): MembersStateProps => {
  const {event} = ownProps
  const showMembers = isSingleEventWidget(state.component) ? isMembersVisible(state, event) : isMembersEnabled(state)

  return {
    onClick: actions.openMembersModal,
    showMembers,
  }
}

export const Members = connect<MembersOwnProps, MembersStateProps>(mapRuntime)(withTranslation()(MembersPresentation))
export * from './interfaces'
