import {withTranslation} from 'react-i18next'
import {getEventDateInformation} from '../../../../../../../../../commons/selectors/event'
import {
  getListImageOpacity,
  getListImagePosition,
  getListLocationAndDateFormat,
} from '../../../../../../../../../commons/selectors/settings'
import {
  isDescriptionVisible,
  isFullDateVisible,
  isImageVisible,
  isLocationVisible,
  isMembersVisible,
  isSocialShareVisible,
} from '../../../../../../../../selectors/list-settings'
import {getComponentConfig, isRibbonVisible} from '../../../../../../../../selectors/settings'
import {AppProps} from '../../../../../../../app/interfaces'
import {connect} from '../../../../../../../runtime-context/connect'
import {MobileMonthlyEventDetailsOwnProps, MobileMonthlyEventDetailsRuntimeProps} from './interfaces'
import {MobileMonthlyEventDetails as MobileMonthlyEventDetailsPresentation} from './mobile-monthly-event-details'

const mapRuntime = (
  {state, actions}: AppProps,
  {event}: MobileMonthlyEventDetailsOwnProps,
): MobileMonthlyEventDetailsRuntimeProps => {
  const componentConfig = getComponentConfig(state)

  return {
    imagePosition: getListImagePosition(componentConfig),
    imageOpacity: getListImageOpacity(componentConfig),
    locationAndDateFormat: getListLocationAndDateFormat(componentConfig),
    showImage: Boolean(event.mainImage) && isImageVisible(state),
    showLocation: isLocationVisible(state),
    showFullDate: isFullDateVisible(state),
    showDescription: isDescriptionVisible(state),
    showShare: isSocialShareVisible(state),
    showMembers: isMembersVisible(state, event),
    date: getEventDateInformation(state, event.id).fullDate,
    closeMonthlyCalendarEvent: actions.closeMonthlyCalendarEvent,
    showRibbon: isRibbonVisible(state, event),
  }
}

export const MobileMonthlyEventDetails = connect<
  MobileMonthlyEventDetailsOwnProps,
  MobileMonthlyEventDetailsRuntimeProps
>(mapRuntime)(withTranslation()(MobileMonthlyEventDetailsPresentation))
export * from './interfaces'
