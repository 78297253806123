import {getElementHeight} from '../../../commons/utils/dom'
import {setHeight} from '../../../commons/utils/wix-sdk'
import s from './list-layout.scss'

export const resizeContainer = () => {
  const height = getElementHeight(`.${s.container}`)

  if (height) {
    setHeight(height)
  }
}
