import {ImageService, hasImage} from '@wix/wix-events-commons-statics'
import React from 'react'
import classNames from 'classnames'
import {getImageStyle} from '../../../commons/utils/image'
import s from './event-image.scss'
import {EventImageProps} from '.'

const fastLoadContainer = {width: 10, height: 10}

export class EventImage extends React.PureComponent<EventImageProps, {element: HTMLElement}> {
  state = {element: null}

  imageAvailable = () => hasImage(this.props.event)

  setRef = (element: HTMLElement) => this.setState({element})

  getImageStyle = (fastLoad = false) => {
    const {event, position, opacity, fitImage} = this.props
    let {container} = this.props

    if (container) {
      if (fastLoad) {
        container = {
          height: Math.ceil(container.height / 30),
          width: Math.ceil(container.width / 30),
        }
      }
    } else {
      const {width: containerWidth, height: containerHeight} =
        this.state.element?.getBoundingClientRect() || fastLoadContainer
      if (fastLoad) {
        container = fastLoadContainer
      } else {
        container = ImageService.getResizedImageSize(
          event.mainImage.width,
          event.mainImage.height,
          containerWidth,
          containerHeight,
        )
      }
    }

    return getImageStyle({
      image: event.mainImage,
      container,
      opacity,
      position,
      fitImage,
    })
  }

  render() {
    if (!this.imageAvailable()) {
      return null
    }

    const {noFast, className, event} = this.props
    const {src, styles} = this.getImageStyle()
    const {src: srcFast, styles: stylesFast} = this.getImageStyle(true)

    return (
      <div className={classNames(s.eventImageContainer, className)} ref={this.setRef}>
        {noFast ? null : <img src={srcFast} className={s.fastLoadImage} style={stylesFast} data-hook="fastLoadImage" />}
        <img src={src} className={s.image} style={styles} data-hook="image" alt={event.title} />
      </div>
    )
  }
}
