import classNames from 'classnames'
import React, {CSSProperties, KeyboardEvent} from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {DesktopItem, MobileItem} from '../item'
import s from './item.scss'
import {ItemContainerProps} from '.'

export const ItemContainer = ({
  t,
  event,
  opened,
  mobile,
  listLayout,
  additionalComponentsHidden,
  close,
  open,
  listStripWidth,
  listDividerWidth,
  lastItem,
}: ItemContainerProps) => {
  const [hovered, setHovered] = React.useState(false)

  const onKeyPress = (e: KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter') {
      toggle(e)
    }
  }

  const onMouseEnter = () => {
    setHovered(true)
  }

  const onMouseLeave = () => {
    setHovered(false)
  }

  const toggle = (e) => {
    if (opened) {
      close()
    } else if (!additionalComponentsHidden) {
      open(event.id)
    }
    e.stopPropagation()
  }

  const getContainerStyle = (): CSSProperties => {
    const padding = `${listStripWidth + (mobile ? 0 : 10)}px 0`

    if (hovered || opened) {
      return {
        padding,
        marginTop: -listDividerWidth,
        borderBottomWidth: listDividerWidth,
        borderTopWidth: listDividerWidth,
      }
    }

    return {
      padding,
      borderBottomWidth: listDividerWidth,
      borderBottomColor: lastItem ? 'transparent' : undefined,
    }
  }

  return (
    <li
      style={getContainerStyle()}
      onKeyPress={onKeyPress}
      tabIndex={0}
      data-hook={DH.listItem}
      className={classNames(s.container, mobile && !listLayout ? s.cardBorderColor : s.listDividerColor, {
        [s.listHoveredBorderColor]: listLayout && (hovered || opened),
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={toggle}
    >
      {mobile ? (
        <MobileItem t={t} event={event} hovered={hovered} />
      ) : (
        <DesktopItem t={t} event={event} hovered={hovered} />
      )}
    </li>
  )
}
