import {Spinner as TPASpinner, SpinnerProps as TPASpinnerProps} from 'wix-ui-tpa/Spinner'
import React from 'react'
import classNames from 'classnames'
import {classes} from './spinner.st.css'

interface SpinnerProps extends TPASpinnerProps {
  className?: string
  calendar?: boolean
  calendarButton?: boolean
}

export const Spinner = ({className, calendar, calendarButton, ...rest}: SpinnerProps) => (
  <TPASpinner
    {...rest}
    className={classNames(
      calendar ? classes.calendar : calendarButton ? classes.calendarButton : classes.list,
      className,
    )}
  />
)
