import classNames from 'classnames'
import React from 'react'
import {Resizable} from '../../../commons/components/hoc/resizable'
import {Title} from '../list-title'
import {LoadMoreButton} from '../load-more-button'
import SideBySideItem from './side-by-side-item'
import s from './side-by-side.scss'
import {Utils} from './utils'
import {SideBySideProps} from '.'

export class SideBySide extends React.Component<SideBySideProps> {
  utils: Utils

  constructor(props: SideBySideProps) {
    super(props)
    this.utils = new Utils()
  }

  resizableProps = () => {
    const {showImage, showMembers, showRibbon, showSocial, showDescription, events} = this.props
    return {
      showImage,
      showMembers,
      showRibbon,
      showSocial,
      showDescription,
      eventCount: events.length,
    }
  }

  render() {
    const {events, showImage, margins, width, t} = this.props

    this.utils.resetItems()

    return (
      <Resizable {...this.resizableProps()} resizeFunc={this.resize}>
        <div className={s.container}>
          <Title />
          <ul
            className={s.items}
            data-hook="side-by-side-items"
            style={{minWidth: this.utils.getMinItemsWidth(showImage)}}
          >
            {events.map((event: ExtendedEvent, index: number) => (
              <li
                key={event.id}
                ref={(element) => this.utils.addItem(element)}
                className={classNames(s.item, this.utils.itemsHeightClass(width))}
                data-hook="side-by-side-item"
                style={{marginBottom: margins}}
              >
                <SideBySideItem event={event} evenItem={!!(index % 2)} t={t} />
              </li>
            ))}
          </ul>
          <LoadMoreButton t={t} />
        </div>
      </Resizable>
    )
  }

  resize = () => {
    this.utils.setItemsHeight()

    if (this.props.editor) {
      this.utils.resizeContainer(this.props.showImage)
    }
  }
}
