import {withTranslation, WithTranslation} from 'react-i18next'
import {getEventDateInformation} from '../../../../../../../commons/selectors/event'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {DayEvents as DayEventsPresentation} from './day-events'

export interface EventInfoOwnProps {
  events: wix.events.Event[]
}

export interface EventInfoRuntimeProps {
  firstEventTime: string
}

export interface EventInfoProps extends EventInfoRuntimeProps, EventInfoOwnProps, WithTranslation {}

const mapRuntime = (context: AppProps, {events}: EventInfoOwnProps): EventInfoRuntimeProps => ({
  firstEventTime: getEventDateInformation(context.state, events[0].id).startTime,
})

export const DayEvents = connect<EventInfoOwnProps, EventInfoRuntimeProps>(mapRuntime)(
  withTranslation()(DayEventsPresentation),
)
