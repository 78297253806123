import React from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {EventImage} from '../../../event-image'
import s from './image.scss'
import {ImageProps} from '.'

export const Image = ({event, containerWidth = '100%', containerHeight = '100%', squareImage = false}: ImageProps) => {
  const style = {
    width: containerWidth,
    height: squareImage ? '100%' : containerHeight,
    paddingTop: squareImage ? '100%' : '',
  }

  if (event.mainImage && event.mainImage.id) {
    return (
      <div data-hook={DH.listImage} className={s.listImageBackgroundColor} style={style}>
        <EventImage event={event} className={squareImage ? s.square : undefined} />
      </div>
    )
  }

  const placeholderStyle = {...style, paddingTop: squareImage ? '100%' : 'initial'}

  return <div data-hook={DH.listImagePlaceholder} className={s.listImageBackgroundColor} style={placeholderStyle} />
}
