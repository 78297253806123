import classNames from 'classnames'
import React from 'react'
import {BUTTONS_STYLE} from '@wix/wix-events-commons-statics'
import sc from '../../classes.scss'
import {Spinner} from '../../spinner'
import s from './today-button.scss'
import {TodayButtonProps} from '.'

export const TodayButton = ({
  onClick,
  dataHook,
  isMobile,
  loading,
  t,
  todayButtonText,
  todayButtonStyle,
}: TodayButtonProps) => {
  const getStyleClassNames = () => ({
    [s.full]: [BUTTONS_STYLE.FULL, BUTTONS_STYLE.FULL_ROUNDED].includes(todayButtonStyle),
    [s.rounded]: [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(todayButtonStyle),
  })

  return (
    <div className={classNames(s.containerr, {[s.mobile]: isMobile})}>
      {loading ? <Spinner className={s.loader} diameter={20} calendarButton /> : null}
      <button
        data-hook={dataHook}
        onClick={() => onClick()}
        type="button"
        className={classNames(s.button, sc.ellipsis, getStyleClassNames(), {
          [s.mobile]: isMobile,
          [s.loading]: loading,
        })}
      >
        {todayButtonText || t('layout.calendar.monthly.controls.today')}
      </button>
    </div>
  )
}
