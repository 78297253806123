import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary'
import {withLeanStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withLeanStyles'
import {ErrorPage} from '../../../commons/components/error-page'
import {getWidgetSentryConfig} from '../../utils/sentry'
import {App} from './app'
import defaults from './stylesParams'

export default {
  component: withSentryErrorBoundary(
    withLeanStyles(App, {
      cssPath: ['eventsWidgetNoCss.min.css'],
      defaults,
    }),
    getWidgetSentryConfig('events-widget-viewer'),
    withLeanStyles(ErrorPage, {cssPath: ['eventsWidgetNoCss.min.css'], defaults}),
  ),
}
