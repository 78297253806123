import React from 'react'
import ChevronLeft from 'wix-ui-icons-common/ChevronLeft'
import ChevronRight from 'wix-ui-icons-common/ChevronRight'
import {CalendarLoading} from '../../../types/state'
import {Title} from '../../list-title'
import {Spinner} from '../../spinner'
import {TodayButton} from '../today-button'
import {MonthlyCalendarGrid} from './grid'
import s from './monthly-calendar.scss'
import {MonthlyCalendarProps} from '.'

export const MonthlyCalendar = ({
  addCalendarMonth,
  subCalendarMonth,
  resetCalendar,
  fullMonthText,
  loading,
  t,
}: MonthlyCalendarProps) => {
  return (
    <div className={s.monthlyCalendar} data-hook="monthly-desktop-calendar">
      <Title />
      <div className={s.controls}>
        <div className={s.monthControls}>
          <button
            onClick={() => subCalendarMonth()}
            type="button"
            className={s.monthButton}
            data-hook="calendar-previous-month-button"
            aria-label={t('a11y.previousMonth')}
          >
            <ChevronLeft size="1.5em" />
          </button>
          <div className={s.month} data-hook="calendar-month">
            {loading === CalendarLoading.NEXT || loading === CalendarLoading.PREVIOUS ? (
              <Spinner diameter={20} calendar />
            ) : (
              fullMonthText
            )}
          </div>
          <button
            onClick={() => addCalendarMonth()}
            type="button"
            className={s.monthButton}
            data-hook="calendar-next-month-button"
            aria-label={t('a11y.nextMonth')}
          >
            <ChevronRight size="1.5em" />
          </button>
        </div>
        <div className={s.today}>
          <TodayButton
            onClick={resetCalendar}
            loading={loading === CalendarLoading.DEFAULT}
            dataHook="calendar-today-button"
          />
        </div>
      </div>
      <div className={s.grid}>
        <MonthlyCalendarGrid />
      </div>
    </div>
  )
}
