import classNames from 'classnames'
import React from 'react'
import s from './browser-only.scss'
import {BrowserOnlyState} from './interfaces'

export class BrowserOnly extends React.PureComponent<{}, BrowserOnlyState> {
  state: BrowserOnlyState = {
    loading: true,
  }

  componentDidMount() {
    this.setState({loading: false})
  }

  render() {
    return <span className={classNames({[s.loading]: this.state.loading})}>{this.props.children}</span>
  }
}
